import { Link } from 'react-router-dom'
import { Fab, Grid, Typography } from '@material-ui/core'
import { useStyles } from './About.styles'

const About = () => {
  const classes = useStyles()
  return (
    <>
      <Typography variant="h3" className={classes.typography}>
        About Us
      </Typography>
      <div className={classes.aboutUs}>
        <Grid container alignItems="center">
          <Grid item md={6} sm={12}>
            <img src="/static/images/custom1.jpg" alt="" />
          </Grid>
          {/* @ts-ignore */}
          <Grid item md={6} sm={12} align="left">
            <div className={classes.leftText}>
              <Typography variant="caption">Who we are</Typography>
              <Typography variant="h2">
                Get to know about <br />
                <em>our company</em>
              </Typography>
              <Typography variant="body1">
                <span style={{ whiteSpace: 'pre-line' }}>
                  {`Welcome to Panalax. We built this custom “shop chat”, specifically for the custom shop community. Panalax
                makes it easy and inexpensive for you to stay in constant touch with your clients, to share pictures of the builds
                in progress, discuss options, trims, parts and costs, ask and answer questions and record agreements on changes.`}
                </span>
              </Typography>
              <Fab
                variant="extended"
                size="medium"
                color="primary"
                aria-label="add"
                className={classes.fab}
              >
                <Link to="about">Read more</Link>
              </Fab>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default About
